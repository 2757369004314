import { messages } from '@/data/translation-messages';

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'cs',
    fallbackLocale: 'cs',
    messages
  };
})
